
/**
 * @name: 档口管理-档口信息管理
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 档口管理-档口信息管理
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { gateQueryApi, gateCreateApi, gateModifyApi, gateDetailApi } from "@/apis/gate/info"
import config from "@/config"
import type { ICrudOption } from "@/types/m-ui-crud"
import type { IGate, IGateParams } from "@/apis/gate/info/types"

const checkAlphaNumeric = (rule: any, value: any, callback: any) => {
  if (!/^[a-zA-Z0-9]+$/.test(value)) {
    return callback(new Error('请输入字母或数字'));
  }
  callback()
}

@Component({})
export default class gateInfo extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IGate[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IGateParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IGate> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    column: [
      {
        label: "档口ID",
        prop: "id",
        width: 180,
        align: "left",
        addHide: true,
        editSlot: true,
        span: 24
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center",
        search: true,
        span: 12,
        maxlength: 30,
        rules: [
          {required: true, message: "请输入档口名称", trigger: "blur"}
        ]
      },
      {
        label: "档口门牌号",
        prop: "gateSn",
        align: "center",
        search: true,
        span: 12,
        maxlength: 30,
        rules: [
          {required: true, message: "请输入档口门牌号", trigger: "blur"},
          {validator: checkAlphaNumeric, trigger: "blur" }
        ]
      },
      {
        label: "档口营业许可证",
        prop: "gateCredentialPic",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        imgSuffix: ";",
        span: 24,
        addSlot: true,
        editSlot: true
      }
    ]
  }

  beforeUpload (file: File) {
    const isFileType = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isFileType) {
      this.$message.error('上传图片只能是 JPG、PNG 格式!');
    }
    return isFileType
  }

  openDetail (row: IGate, index: number) {
    gateDetailApi(row.id).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  /**
   * 创建
   */
  rowSave (form: IGate, done: Function, loading: Function) {
    gateCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  /**
   * 编辑
   */
  rowEdit (form: IGate, done: Function, loading: Function) {
    gateModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true
    gateQueryApi(this.queryParam).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  created () {
    this.getList()
  }
}
